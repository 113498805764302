import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="page-container" id="footer">
        <p style={{ color: "grey" }}>copyright 2019 Sarah Levins</p>
      </div>
    );
  }
}

export default Footer;
